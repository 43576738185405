import React from 'react'

import { InfoSection } from '../Common'

export const Greetings = () => {
  const urls = [
    'https://i.pinimg.com/564x/a7/4a/36/a74a369ac1c59790dce43a3fab22882d.jpg',
    'https://i.pinimg.com/564x/a7/4a/36/a74a369ac1c59790dce43a3fab22882d.jpg',
    'https://i.pinimg.com/564x/a7/4a/36/a74a369ac1c59790dce43a3fab22882d.jpg',
    'https://i.pinimg.com/564x/a7/4a/36/a74a369ac1c59790dce43a3fab22882d.jpg'
  ]
  return (
    <div className='flex flex-col pt-14 mb-4 sm:pt-18 sm:px-20 px-6 font-sans bg-stone-100 dark:bg-stone-600 dark:text-white'>
        
        <div className='w-full inline-flex flex-nowrap'>          
          <ul className='flex items-center justify-center gap-2 [&_img]:max-w-28 animate-loop-scroll '>
          {  urls.map( (url) => {
            return (
              <li><img src='https://i.pinimg.com/564x/a7/4a/36/a74a369ac1c59790dce43a3fab22882d.jpg'/></li>
            )})
          }
          </ul>

          <ul className='ml-2 flex items-center justify-center gap-2 [&_img]:max-w-28 animate-loop-scroll' aria-hidden="true">
          {  urls.map( (url) => {
            return (
              <li><img src='https://i.pinimg.com/564x/a7/4a/36/a74a369ac1c59790dce43a3fab22882d.jpg'/></li>
            )})
          }
          </ul>        
        </div>
        
        <InfoSection title='Thank you'>
            
            <div className='text-sm text-center'>
                For making one of the most important days of my life special.<br/><br/>
                To those who saw my potential and believed in me from the very beginning.<br/><br/>
                To those who have supported me emotionally and financially during these university years.<br/><br/>
                To those who encouraged me to study even when I didn't want to.<br/><br/>
                <br/><hr/><br/>
                To those who helped me with the preparations for the proclamation and the celebration.<br/><br/>
                To those who traveled from afar and to those who set aside other commitments to be present.<br/><br/>
                To everyone who couldn't be there last time but managed to come this time.<br/><br/>
                To those who couldn't make it this time but still made their presence felt.<br/><br/>
                <br/><hr/><br/>
                To those who always paid for me so I could be present and spend time together.<br/><br/>
                To those who reached out, even though I was fading away.<br/><br/>
                To those who treated me as if I had never disappeared.<br/><br/>
            </div>

            <div className='text-center italic'>
              19-03-2024
            </div>
    
        </InfoSection>
    </div>
  )
}
