import React from 'react'

import { Greetings } from './subcomponents/Greetings';

export const GraduationPage = () => {
  const currentDate = new Date()
  return (
    <div>
        <Greetings />
    </div>
  )
}
